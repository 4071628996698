/* page ID overwrites */
body#proDevVideos,
body#districtPortalTools {
  margin-top: 50px;

  video {
    width: 100%;
    object-fit: cover;
  }
}
body#districtPortalTools {
  video {
    border: 1px solid $Disabled;
  }
}

/* Banner */
.banner {
  background: $GreyDark;
  margin-bottom: 25px;

  h1,h2,h3,h4,h5,h6,p {
    color: $White;
  }

  #districtPortalTools & {
    margin-bottom: 0;
  }
}
.banner-padding {
  padding-top: 15px;
  padding-bottom: 15px;

  &:first-child {
    padding-left: 55px;
  }

  &:last-child {
    @media only screen and (min-width: 968px){
      padding-left: 0px;
    }
  }
}
.banner-text {
  @media only screen and (max-width: 767px){
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 45px;
  }
}

/* Layout */
.pd-video-row,
.pd-video-row-last {
  margin-top: 15px;
  padding-bottom: 25px;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid $Disabled;
  background: $White;

  #districtPortalTools & {
    margin-top: 0;
    margin-bottom: 5rem;
    border-bottom: none;
  }
}
.pd-video-row-last {
  border-bottom: none;
}
.mobilePadBot {
  @media only screen and (max-width: 767px){
    margin-bottom: 4% !important; /* matches .playlistWrap-video */
  }
}

.desktop-margin-lr {
  @media only screen and (min-width: 768px){
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

/* video links */
a.poster-link {
  color: $Text;

  img {
    margin-bottom: 5px;
    border: 4px solid $Disabled;
  }
  p {
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    color: $wcagAAA-dashBGreen-hover;
    text-decoration: none;

    img {
      border: 4px solid $wcagAAA-dashBGreen-hover;
    }
  }

  #districtPortalTools & {
    display: block;

    &:hover,
    &:focus {
      color: $Text;

      img {
        border: 4px solid $DashBBlue;
      }
    }
  }
}
.playlistWrap-video.uk-active {
  pointer-events: none;
  cursor: default;

  a.poster-link {
    color: $DashBGreen;
    text-decoration: none;

    img,
    &:hover img,
    &:focus img {
      border: 4px solid $DashBGreen;
    }
  }
}

/* Playlist layout */
.playlistWrap {
	display: flex;
  flex-wrap: wrap;
}
.playlistWrap-video {
	width: 30%;
	margin: 0 2% 4% 2%;

  &:nth-of-type(3n) {
  	margin-right: 0;
  }
  &:nth-of-type(3n+1) {
  	margin-left: 0;
  }
}

.uk-button use.icon-white {
  fill: $White;
}
